// src/components/ProjectCard.js
import React from 'react';

const ProjectCard = ({ logo, title, description, tags, link }) => {
  return (
    <div className="bg-gray-800 rounded-lg p-6 flex items-start space-x-4">
      <div className="flex-shrink-0">
        <a href={link} target="_blank" rel="noopener noreferrer" className="block hover:opacity-80 transition-opacity">
          <img src={logo} alt={`${title} logo`} className="w-12 h-12 object-contain" />
        </a>
      </div>
      <div>
        <h3 className="text-xl font-semibold mb-2">
          <a href={link} target="_blank" rel="noopener noreferrer" className="hover:text-yellow-500 transition-colors">
            {title}
          </a>
        </h3>
        <p className="text-gray-300 mb-4">{description}</p>
        <div className="flex flex-wrap gap-2">
          {tags.map((tag, index) => (
            <span key={index} className="px-2 py-1 bg-gray-700 text-sm rounded-full">
              {tag}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;