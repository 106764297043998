import React from 'react';
import ProjectCard from './components/ProjectCard';
import projectTwitterAutoRefreshLogo from './assets/images/project-twitter-auto-refresh.webp';
import projectNeotchLogo from './assets/images/project-neotch.webp';
import xSvg from './assets/images/x.svg';  // Import the X SVG

const Portfolio = () => {
  return (
    <div className="min-h-screen bg-gray-900 text-white">
      <div className="max-w-screen-lg mx-auto p-8">
        <header className="mb-12">
          <h1 className="text-4xl font-bold mb-4">Hi there, I'm <span className="text-yellow-500">Kaalni</span> 👋</h1>
          <p className="text-lg mb-4">
            I love experimenting with code and turning small ideas into functional projects. My passion lies in indiehacking—
            creating lightweight, innovative solutions that solve real problems or simply bring joy. I thrive on the thrill of
            exploring new technologies, learning by doing, and building things just for fun. If you share the same spirit of
            creativity and curiosity, let's connect!
          </p>
          <a href="https://x.com/kaalni_dev" target="_blank" rel="noopener noreferrer" className="inline-block text-blue-400 hover:text-blue-300 transition-colors">
            <img src={xSvg} alt="X (Twitter) Profile" className="w-6 h-6 x-icon" />
          </a>
        </header>

        <main>
          <h2 className="text-2xl font-semibold mb-6">My recent <span className="text-yellow-500">Projects</span></h2>
          <div className="space-y-6">
            <ProjectCard
              logo={projectTwitterAutoRefreshLogo}
              title="X (Twitter) Auto Refresher"
              description="Enhance your browsing on X (Twitter) with this extension that automates your feed refresh. Tailor your X (Twitter) experience with customizable intervals, ensuring you're always up-to-date with the latest tweets."
              tags={['Chrome Extension', 'JavaScript']}
              link="https://chromewebstore.google.com/detail/mihjenkihajdgclhgheccildhbocbheb?ref=kaalni"
            />
            <ProjectCard
              logo={projectNeotchLogo}
              title="Neotch - Twitch Helper"
              description="Meet Neotch, the Chrome extension designed to enhance your Twitch experience. Neotch offers essential features for viewers seeking a more streamlined and focused viewing. Its user-friendly interface provides the tools you need to watch Twitch your way."
              tags={['Chrome Extension', 'Plasmo Framework', 'JavaScript']}
              link="https://chromewebstore.google.com/detail/neotch-twitch-helper/lffhpgamallkbeelnlhddfpiglckgceg?ref=kaalni"
            />
          </div>
        </main>

        <footer className="mt-12 text-center text-sm text-gray-500">
          © Copyright 2024 by kaalni.
        </footer>
      </div>
    </div>
  );
};

export default Portfolio;